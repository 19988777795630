import {
  UPDATE_GLOBAL_CONFIG,
  RESET_GLOBAL_SETTING,
  UPDATE_SEARCH_ENGINE_LIST,
  UPDATE_THEME,
  UPDATE_WEBSITE_LIST,
  UPDATE_WEBSITE_CATEGORY,
  UPDATE_WEBSITE_SITE_LIST,
  UPDATE_WEBSITE_SITE,
  ADD_WEBSITE_CATEGORY,
  ADD_WEBSITE_SITE,
  UPDATE_HOT_PLATFORM,
  UPDATE_THEME_SCHEME
} from './mutation-types'
import { searchEngineList as rawSearchEngineList } from '@/config'

export default {
  updateGlobalConfig({ commit, state: { globalConfig, searchEngineList: searchEngineList_state }}, config) {
    if (Object.prototype.toString.call(config) === '[object Object]') {
      if (!config.searchSettings) {
        config.searchSettings = {}
      }
      if (config.searchSettings.defaultEngine && config.searchSettings.defaultEngine !== 'custom' && rawSearchEngineList.findIndex(item => item.key === config.searchSettings.defaultEngine) === -1) {
        const engineNames = rawSearchEngineList.map(item => item.key).join('、') + rawSearchEngineList.length
        throw new RangeError('请确保默认搜索引擎为' + engineNames + '者之一')
      }
      if (config.searchSettings.jumpMethod && config.searchSettings.jumpMethod !== '_blank' && config.searchSettings.jumpMethod !== '_self') {
        throw new RangeError('请确保跳转方式为_blank和_self二者之一。')
      }
      // 多级结构，在action这里先处理一遍，mutation里面直接一层赋值
      config.searchSettings = {
        ...globalConfig.searchSettings,
        ...config.searchSettings
      }
      config.hotPlatformSettings = {
        ...globalConfig.hotPlatformSettings,
        ...config.hotPlatformSettings
      }
      if (config.searchSettings.defaultEngine !== globalConfig.searchSettings.defaultEngine) {
        const list = [...searchEngineList_state]
        const findIndex = list.findIndex(item => item.key === config.searchSettings.defaultEngine)
        if (findIndex !== -1) {
          const findItem = list[findIndex]
          list.splice(findIndex, 1)
          list.unshift(findItem)
          commit(UPDATE_SEARCH_ENGINE_LIST, list)
        }
      }
      commit(UPDATE_GLOBAL_CONFIG, config)
    }
  },
  resetGlobalSetting({ commit, state: { searchEngineList: searchEngineList_state }}) {
    commit(RESET_GLOBAL_SETTING)
    const searchEngineList = [...rawSearchEngineList]
    const findCustom = searchEngineList_state.find(item => item.key === 'custom')
    if (findCustom) {
      searchEngineList.push(findCustom)
    }
    commit(UPDATE_SEARCH_ENGINE_LIST, searchEngineList)
    // this.dispatch('updateSearchEngineList', searchEngineList);
  },
  updateSearchEngineList({ commit }, list) {
    if (Array.isArray(list)) {
      commit(UPDATE_SEARCH_ENGINE_LIST, list)
    }
  },
  updateTheme({ commit }, theme) {
    if (theme === 'dark' || theme === 'light') {
      commit(UPDATE_THEME, theme)
    }
  },
  updateThemeScheme({ commit }, themeScheme) {
    commit(UPDATE_THEME_SCHEME, themeScheme)
  },
  updateWebsiteList({ commit }, websiteList) {
    if (Array.isArray(websiteList)) {
      // 分类排序按顺序赋值score
      websiteList.forEach((category, index) => {
        category.websites = category.websites || []
        category.websites.forEach((website, index) => { website.score = index + 1 })
        category.score = index + 1
      })
      commit(UPDATE_WEBSITE_LIST, websiteList)
    }
  },
  updateWebsiteCategory({ commit }, updateCategory) {
    commit(UPDATE_WEBSITE_CATEGORY, updateCategory)
  },
  updateWebsiteSiteList({ commit }, updateSiteListParam) {
    updateSiteListParam.websites.forEach((website, index) => { website.score = index + 1 })
    commit(UPDATE_WEBSITE_SITE_LIST, updateSiteListParam)
  },
  updateWebsiteSite({ commit }, updateSiteParam) {
    commit(UPDATE_WEBSITE_SITE, updateSiteParam)
  },
  addWebsiteCategory({ commit }, addCategory) {
    commit(ADD_WEBSITE_CATEGORY, addCategory)
  },
  deleteWebsiteCategory({ commit, state: { websiteList }}, categoryId) {
    const categories = websiteList.filter(item => item.categoryId !== categoryId)
    commit(UPDATE_WEBSITE_LIST, categories)
  },
  addWebsiteSite({ commit }, addSite) {
    commit(ADD_WEBSITE_SITE, addSite)
  },
  deleteWebsiteSite({ commit, state: { websiteList }}, { categoryId, siteId }) {
    const category = websiteList.find(item => item.categoryId === categoryId)
    if (category) {
      const websites = category.websites.filter(item => item.id !== siteId)
      commit(UPDATE_WEBSITE_SITE_LIST, { categoryId, websites })
    }
  },
  addHotPlatform({ commit, state: { hotPlatformList }}, hotPlatform) {
    const score = hotPlatformList.length
    const addHotPlatform = {
      key: hotPlatform.key,
      name: hotPlatform.name,
      logoUrl: hotPlatform.logoUrl,
      score: score
    }
    hotPlatformList.push(addHotPlatform)
    commit(UPDATE_HOT_PLATFORM, hotPlatformList)
  },
  deleteHotPlatform({ commit, state: { hotPlatformList }}, hotPlatformKey) {
    const hotPlatforms = hotPlatformList.filter(item => item.key !== hotPlatformKey)
    hotPlatforms.forEach((hotPlatform, index) => { hotPlatform.score = index + 1 })
    commit(UPDATE_HOT_PLATFORM, hotPlatforms)
  },
  updateHotPlatform({ commit }, hotPlatformList) {
    hotPlatformList.forEach((hotPlatform, index) => { hotPlatform.score = index + 1 })
    commit(UPDATE_HOT_PLATFORM, hotPlatformList)
  }
}
