var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"fly-button",class:[
    _vm.buttonType,
    _vm.buttonSize,
    {
      'is-disabled': _vm.disabled,
      'is-loading': _vm.loading,
    }
  ],attrs:{"disabled":_vm.disabled || _vm.loading,"type":_vm.nativeType},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('div',{staticClass:"icon-loading"}):_vm._e(),(_vm.icon && !_vm.loading)?_c('svg-icon',{attrs:{"icon-class":_vm.icon}}):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }