import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import common from './modules/common'
import { ThemeSchemeDefault } from '@/config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common
  },
  state,
  actions,
  mutations,
  getters,
  plugins: [
    store => {
      if (store.getters.themeScheme !== ThemeSchemeDefault) {
        document.documentElement.setAttribute('theme-scheme', store.getters.themeScheme)
      }
    }
  ]
})
