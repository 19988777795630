<template>
  <transition
    name="dialog-fade"
    @before-enter="beforeEnter"
    @after-leave="removeEventListener"
  >
    <div v-show="visible" class="fly-dialog-mask" @dblclick.self="handleCloseDialogOnDbclick">
      <div class="fly-dialog">
        <div class="fly-dialog__title">
          <button
            v-if="showCloseBtn"
            type="button"
            class="fly-dialog-titlebtn"
            title="关闭"
            @click="handleCloseDialog"
          >
            <svg-icon :icon-class="$store.getters.theme ? 'tool-close' : 'tool-close-dark'" />
          </button>
        </div>
        <div class="fly-dialog__content">
          <slot />
        </div>
        <div class="fly-dialog__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FlyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 是否显示关闭按钮
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    // 默认esc关闭
    closeOnEscape: {
      type: Boolean,
      default: true
    },
    // 默认双击关闭
    closeOnDbclick: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.addEventListener()
      }
    }
  },
  mounted() {
    this.addEventListener()
  },
  methods: {
    addEventListener() {
      if (this.closeOnEscape) {
        document.addEventListener('keydown', this.handleCloseDialogOnEscape)
      }
    },
    // 离开动画结束之后清除事件监听
    removeEventListener() {
      document.removeEventListener('keydown', this.handleCloseDialogOnEscape)
    },
    handleCloseDialogOnDbclick() {
      if (this.closeOnDbclick) {
        this.handleCloseDialog()
      }
    },
    handleCloseDialogOnEscape(e) {
      if (this.closeOnEscape && e.keyCode === 27) {
        this.handleCloseDialog()
      }
    },
    handleCloseDialog() {
      this.$emit('update:visible', false)
      // 关闭时的事件
      this.$emit('close')
    },
    beforeEnter() {
      // 进入之前
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-fade-enter-active {
  animation: dialog-fade-in .3s;
}

.dialog-fade-leave-active {
  animation: dialog-fade-out .3s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
    backdrop-filter: blur(0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    backdrop-filter: blur(10px);
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.5;
    backdrop-filter: blur(10px);
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
    backdrop-filter: blur(0);
  }
}
.fly-dialog-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  backdrop-filter: blur(10px);
  user-select: none;
  z-index: 2023;
}
.fly-dialog {
  position: relative;
  margin: 50px auto;
  padding: 50px;
  width: 50%;
  background-color: var(--theme-dialog-bg);
  border: 1px solid var(--theme-panel-border);
  border-radius: var(--border-radius);
  user-select: text;
  @media screen and (max-width: 880px) {
    padding: 10px;
    width: calc(100% - 60px);
  }
  .fly-dialog-titlebtn {
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 35px;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;
    .svg-icon {
      width: 100%;
      height: 100%;
    }
  }
  .fly-dialog__content {
    margin-top: 24px;
  }
}
</style>
