<template>
  <div>
    <component :is="themeComponent" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { themeSchemes } from '@/config'

export default {
  name: 'HomePage',
  components: {

  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['themeScheme']),
    themeComponent() {
      return themeSchemes.find(item => item.value === this.themeScheme).component
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
