export const websitePrefix = 'fly-feather-'

// 搜索引擎列表，key值为必须填写且不能重复，否则在vuex中操作时无法判断存储内容是否合法，同时搜索列表渲染绑定值为key值
export const searchEngineList = [
  {
    key: 'google',
    name: '谷歌',
    englishName: 'google',
    iconUrl: 'static/icon/engine/google.svg',
    darkIconUrl: 'static/icon/engine/google-dark.svg',
    url: 'https://www.google.com',
    searchUrlPrefix: 'https://www.google.com/search?q='
  },
  {
    key: 'bing',
    name: '必应',
    englishName: 'bing',
    iconUrl: 'static/icon/engine/bing.svg',
    darkIconUrl: 'static/icon/engine/bing-dark.svg',
    url: 'https://cn.bing.com',
    searchUrlPrefix: 'https://cn.bing.com/search?q='
  },
  {
    key: 'baidu',
    name: '百度',
    englishName: 'baidu',
    iconUrl: 'static/icon/engine/baidu.svg',
    darkIconUrl: 'static/icon/engine/baidu-dark.svg',
    url: 'https://www.baidu.com',
    searchUrlPrefix: 'https://www.baidu.com/s?wd='
  },
  {
    key: 'sogou',
    name: '搜狗',
    englishName: 'sogou',
    iconUrl: 'static/icon/engine/sogou.svg',
    darkIconUrl: 'static/icon/engine/sogou.svg',
    url: 'https://www.sogou.com',
    searchUrlPrefix: 'https://www.sogou.com/web?query='
  },
  {
    key: '360so',
    name: '360搜索',
    englishName: '360so',
    iconUrl: 'static/icon/engine/360so.svg',
    darkIconUrl: 'static/icon/engine/360so.svg',
    url: 'https://www.so.com',
    searchUrlPrefix: 'https://www.so.com/s?q='
  }
]
export const customSearchEngine = {
  key: 'custom',
  name: '自定义',
  englishName: 'custom',
  iconUrl: 'static/icon/engine/custom.svg',
  darkIconUrl: 'static/icon/engine/custom.svg',
  url: '',
  searchUrlPrefix: ''
}
export const globalConfig = {
  iconBorderRadius: 10,
  // 外部链接跳转方式，默认当前页
  externalJumpMethod: '_self',
  // 搜索设置
  searchSettings: {
    // 默认是搜索引擎列表第一个
    defaultEngine: searchEngineList[0].key,
    // 跳转方式，默认当前页
    jumpMethod: '_self',
    // 搜索建议，默认不开启
    searchSuggestion: false,
    // 补全
    searchCompletion: true
  },
  hotPlatformSettings: {
    open: true
  }
}
export const ThemeSchemeDefault = 'default'
export const ThemeSchemeZephyr = 'zephyr'

export const themeSchemes = [
  {
    name: '默认',
    value: ThemeSchemeDefault,
    component: () => import('@/layout/DefaultLayout.vue')
  },
  {
    name: '和风',
    value: ThemeSchemeZephyr,
    component: () => import('@/layout/ZephyrLayout.vue')
  }
]
