<template>
  <div
    :class="[
      type === 'textarea' ? 'fly-textarea' : 'fly-input',
      {
        'is-disabled': disabled
      },
      statusClass
    ]"
  >
    <input
      v-if="type !== 'textarea'"
      ref="input"
      class="fly-input__inner"
      v-bind="$attrs"
      :value="value"
      :tabindex="tabindex"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :autocomplete="autocomplete"
      :aria-label="label"
      :placeholder="placeholder"
      @compositionstart="handleCompositionStart"
      @compositionupdate="handleCompositionUpdate"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    >
    <textarea
      v-else
      ref="textarea"
      class="fly-textarea__inner"
      v-bind="$attrs"
      :value="value"
      :tabindex="tabindex"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :autocomplete="autocomplete"
      :aria-label="label"
      :placeholder="placeholder"
      @compositionstart="handleCompositionStart"
      @compositionupdate="handleCompositionUpdate"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    />
  </div>
</template>
<script>
import { isKorean } from '@/utils/shared'
export default {
  name: 'FlyInput',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: Boolean,
    readonly: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    label: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isComposing: false
    }
  },
  computed: {
    /* nativeInputValue() {
        return this.value === null || this.value === undefined ? '' : String(this.value);
      } */
    statusClass() {
      switch (this.status) {
        case 'warning':
          return 'status-waring'
        case 'error':
          return 'status-error'
        default:
          return ''
      }
    }
  },
  methods: {
    focus() {
      this.getInput().focus()
    },
    blur() {
      this.getInput().blur()
    },
    handleCompositionStart(event) {
      this.$emit('compositionstart', event)
      this.isComposing = true
    },
    handleCompositionUpdate(event) {
      this.$emit('compositionupdate', event)
      const text = event.target.value
      const lastCharacter = text[text.length - 1] || ''
      this.isComposing = !isKorean(lastCharacter)
    },
    handleCompositionEnd(event) {
      this.$emit('compositionend', event)
      if (this.isComposing) {
        this.isComposing = false
        this.handleInput(event)
      }
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea
    },
    /* setNativeInputValue() {
        const input = this.getInput();
        if (!input) return;
        if (input.value === this.nativeInputValue) return;
        input.value = this.nativeInputValue;
      }, */
    handleInput(event) {
      if (this.isComposing) return
      // if (event.target.value === this.nativeInputValue) return;
      this.$emit('input', event.target.value)
    },
    handleFocus(event) {
      this.$emit('blur', event)
    },
    handleBlur(event) {
      this.$emit('blur', event)
    },
    handleChange(event) {
      this.$emit('change', event.target.value)
    }
  }
}
</script>
