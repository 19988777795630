export function isDef(val) {
  return val !== undefined && val !== null
}
export function isKorean(text) {
  const reg = /([(\uAC00-\uD7AF)|\u3130-\u318F])+/gi
  return reg.test(text)
}
/**
 * @description vue-toasted插件的图标使用callback方式，调用这个方法
 * @param {string} icon 图标名称(默认info)
 * @returns {Function} 返回一个函数，返回函数的参数为一个html元素，在元素中加入svg图标，返回元素参数本身
*/
export function toastIcon(icon = 'info') {
  return el => {
    if (el instanceof HTMLElement) {
      el.innerHTML = `<svg class="toast-svg-icon"><use xlink:href="#icon-${icon}"></use></svg>`
    }
    return el
  }
}
