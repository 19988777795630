import { websitePrefix } from '@/config'

/**
 * @description: 保存数据
 * @param {object} options - 保存数据时的配置信息
 * @param {string} options.key - 保存数据时对应的键(key)值
 * @param {string} [options.type='local'] - 存储数据的类型：local：本地存储(默认)，session：会话存储。
 * @param {object} options.data - 保存的数据
 */
export function setStore(options) {
  if (!(Object.prototype.toString.call(options) === '[object Object]')) {
    throw new TypeError('请传入类型为一般对象的参数，且必须包含key属性。')
  }
  let { key } = options
  if (!key) {
    throw new ReferenceError('key属性引用出错，请确保key属性值有效性。')
  }
  key = websitePrefix + key
  const {
    type = 'local',
    data
  } = options
  const dataObj = {
    dataType: typeof data,
    data
  }
  if (type === 'session') {
    window.sessionStorage.setItem(key, JSON.stringify(dataObj))
  } else if (type === 'local') {
    window.localStorage.setItem(key, JSON.stringify(dataObj))
  } else {
    throw new RangeError('type属性的有效取值范围是"local"和"session"，默认为"local",请确保参数正确。')
  }
}

/**
 * @description: 获取数据
 * @param {object} options - 配置信息
 * @param {string} options.key - 数据对应的键(key)值
 * @param {string} [options.type='local'] - 存储数据的类型：local：本地存储(默认)，session：会话存储。
 * @return {*}
 */
export function getStore(options) {
  if (!(Object.prototype.toString.call(options) === '[object Object]')) {
    throw new TypeError('请传入类型为一般对象的参数，且必须包含key属性。')
  }
  let { key } = options
  if (!key) {
    throw new ReferenceError('key属性引用出错，请确保key属性值有效性。')
  }
  key = websitePrefix + key
  const {
    type = 'local'
  } = options
  let dataObj
  if (type === 'session') {
    dataObj = window.sessionStorage.getItem(key)
  } else if (type === 'local') {
    dataObj = window.localStorage.getItem(key)
  } else {
    throw new RangeError('type属性的有效取值范围是"local"和"session"，默认为"local",请确保参数正确。')
  }
  if (!dataObj) {
    return
  }
  dataObj = JSON.parse(dataObj)
  return dataObj.data
}

/**
 * @description: 删除数据
 * @param {object} options - 配置信息
 * @param {string} options.key - 数据对应的键(key)值
 * @param {string} options.type - 存储数据的类型：local：本地存储(默认)，session：会话存储。
 */
export function removeStore(options) {
  if (!(Object.prototype.toString.call(options) === '[object Object]')) {
    throw new TypeError('请传入类型为一般对象的参数，且必须包含key属性。')
  }
  let { key } = options
  if (!key) {
    throw new ReferenceError('key属性引用出错，请确保key属性值有效性。')
  }
  key = websitePrefix + key
  const {
    type = 'local'
  } = options
  if (type === 'session') {
    window.sessionStorage.removeItem(key)
  } else if (type === 'local') {
    window.localStorage.removeItem(key)
  } else {
    throw new RangeError('type属性的有效取值范围是"local"和"session"，默认为"local",请确保参数正确。')
  }
}

/**
 * @description: 清空type对应类型存储的数据
 * @param {string} type - 存储数据的类型：local：本地存储(默认)，session：会话存储。
 */
export function removeAllStore(type = 'local') {
  if (type === 'session') {
    window.sessionStorage.clear()
  } else if (type === 'local') {
    window.localStorage.clear()
  } else {
    throw new RangeError('type属性的有效取值范围是"local"和"session"，默认为"local",请确保参数正确。')
  }
}
