import { removeStore, setStore } from '@/utils/storage'
import {
  UPDATE_GLOBAL_CONFIG,
  RESET_GLOBAL_SETTING,
  UPDATE_SEARCH_ENGINE_LIST,
  UPDATE_THEME,
  UPDATE_WEBSITE_LIST,
  UPDATE_WEBSITE_CATEGORY,
  UPDATE_WEBSITE_SITE_LIST,
  UPDATE_WEBSITE_SITE,
  ADD_WEBSITE_CATEGORY,
  ADD_WEBSITE_SITE,
  UPDATE_HOT_PLATFORM,
  UPDATE_THEME_SCHEME
} from './mutation-types'
import { globalConfig as rawGlobalConfig, ThemeSchemeDefault } from '@/config'
import state from '@/store/state'

export default {
  // 更新全局配置
  [UPDATE_GLOBAL_CONFIG](state, config) {
    state.globalConfig = { ...state.globalConfig, ...config }
    setStore({
      key: 'globalConfig',
      data: state.globalConfig
    })
  },
  [RESET_GLOBAL_SETTING](state) {
    state.globalConfig = { ...rawGlobalConfig, searchSettings: { ...rawGlobalConfig.searchSettings }}
    removeStore({
      key: 'globalConfig'
    })
  },
  [UPDATE_SEARCH_ENGINE_LIST](state, list) {
    state.searchEngineList = list
    const findItem = list.find(item => item.key === 'custom')
    setStore({
      key: 'cusSearchEngine',
      data: {
        key: 'custom',
        searchUrl: findItem && findItem.searchUrlPrefix
      }
    })
    const mapList = list.map(item => item.key)
    setStore({
      key: 'engSortList',
      data: mapList
    })
  },
  // 更新主题
  [UPDATE_THEME](state, theme) {
    state.theme = theme
    document.documentElement.setAttribute('theme', theme)
    setStore({
      key: 'theme',
      type: 'local',
      data: theme
    })
  },
  // 更新预设主题
  [UPDATE_THEME_SCHEME](state, themeScheme) {
    state.themeScheme = themeScheme
    document.documentElement.removeAttribute('theme-scheme')
    if (themeScheme !== ThemeSchemeDefault) {
      document.documentElement.setAttribute('theme-scheme', themeScheme)
    }
    setStore({
      key: 'themeScheme',
      type: 'local',
      data: themeScheme
    })
  },
  // 更新网站列表
  [UPDATE_WEBSITE_LIST](state, websiteList) {
    state.websiteList.splice(0, state.websiteList.length, ...websiteList)
    setStore({
      key: 'websiteList',
      data: state.websiteList
    })
  },
  // 更新网站分类信息
  [UPDATE_WEBSITE_CATEGORY](state, updateCategory) {
    const { categoryId, categoryName } = updateCategory
    const categoryIdx = state.websiteList.findIndex(item => item.categoryId === categoryId)
    if (categoryIdx !== -1) {
      state.websiteList[categoryIdx].categoryName = categoryName
      setStore({
        key: 'websiteList',
        data: state.websiteList
      })
    }
  },
  // 更新网站分类中的网站列表
  [UPDATE_WEBSITE_SITE_LIST](state, updateSiteListParam) {
    const categoryIdx = state.websiteList.findIndex(item => item.categoryId === updateSiteListParam.categoryId)
    if (categoryIdx !== -1) {
      state.websiteList[categoryIdx].websites = [...updateSiteListParam.websites]
      setStore({
        key: 'websiteList',
        data: state.websiteList
      })
    }
  },
  // 更新网站分类中的网站
  [UPDATE_WEBSITE_SITE](state, updateSiteParam) {
    const categoryIdx = state.websiteList.findIndex(item => item.categoryId === updateSiteParam.categoryId)
    if (categoryIdx !== -1) {
      const siteIdx = state.websiteList[categoryIdx].websites.findIndex(item => item.id === updateSiteParam.id)
      if (siteIdx !== -1) {
        const website = state.websiteList[categoryIdx].websites[siteIdx]
        website.name = updateSiteParam.name
        website.url = updateSiteParam.url
        website.logoUrl = updateSiteParam.logoUrl
        // todo 根据名称填充拼音
        website.pinyin = ''
        website.description = ''
        website.keyword = ''
        setStore({
          key: 'websiteList',
          data: state.websiteList
        })
      }
    }
  },
  // 添加网站分类
  [ADD_WEBSITE_CATEGORY](sate, addCategory) {
    const category = { ...addCategory }
    category.score = state.websiteList.length + 1
    category.websites = []
    state.websiteList.push(category)
    setStore({
      key: 'websiteList',
      data: state.websiteList
    })
  },
  // 添加网站分类里的网站
  [ADD_WEBSITE_SITE](state, addSite) {
    const site = { ...addSite }
    const categoryIdx = state.websiteList.findIndex(item => item.categoryId === site.categoryId)
    if (categoryIdx !== -1) {
      // todo 根据名称填充拼音
      site.pinyin = ''
      site.description = ''
      site.keyword = ''
      site.score = state.websiteList[categoryIdx].websites.length + 1
      state.websiteList[categoryIdx].websites.push(site)
      setStore({
        key: 'websiteList',
        data: state.websiteList
      })
    }
  },
  [UPDATE_HOT_PLATFORM](state, hotPlatformList) {
    state.hotPlatformList.splice(0, state.hotPlatformList.length, ...hotPlatformList)
    setStore({
      key: 'hotPlatformList',
      data: state.hotPlatformList
    })
  }
}
