import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Toasted from 'vue-toasted'
import VueSimpleAlert from 'vue-simple-alert'

import FlyDialog from '@/components/common/dialog/FlyDialog'
import FlyButton from './components/common/button/FlyButton'
import FlyInput from './components/common/input/FlyInput'

import './assets/styles/index.scss'
import './assets/svg-icons'

Vue.config.productionTip = false

Vue.use(Toasted, {
  theme: 'bubble',
  position: 'top-center',
  duration: 2500,
  keepOnHover: true,
  iconPack: 'callback'
})
Vue.use(VueSimpleAlert, {
  heightAuto: false,
  confirmButtonText: '确 定',
  cancelButtonText: '取 消',
  focusCancel: true
})

Vue.component('FlyDialog', FlyDialog)
Vue.component('FlyButton', FlyButton)
Vue.component('FlyInput', FlyInput)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#fly-feather')
