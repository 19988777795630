var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.type === 'textarea' ? 'fly-textarea' : 'fly-input',
    {
      'is-disabled': _vm.disabled
    },
    _vm.statusClass
  ]},[(_vm.type !== 'textarea')?_c('input',_vm._b({ref:"input",staticClass:"fly-input__inner",attrs:{"tabindex":_vm.tabindex,"type":_vm.type,"disabled":_vm.disabled,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"aria-label":_vm.label,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"compositionstart":_vm.handleCompositionStart,"compositionupdate":_vm.handleCompositionUpdate,"compositionend":_vm.handleCompositionEnd,"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},'input',_vm.$attrs,false)):_c('textarea',_vm._b({ref:"textarea",staticClass:"fly-textarea__inner",attrs:{"tabindex":_vm.tabindex,"type":_vm.type,"disabled":_vm.disabled,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"aria-label":_vm.label,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"compositionstart":_vm.handleCompositionStart,"compositionupdate":_vm.handleCompositionUpdate,"compositionend":_vm.handleCompositionEnd,"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},'textarea',_vm.$attrs,false))])
}
var staticRenderFns = []

export { render, staticRenderFns }