export default {
  theme: state => state.theme,
  isLightTheme: state => state.theme === 'light',
  themeScheme: state => state.themeScheme,
  globalConfig: state => state.globalConfig,
  iconBorderRadius: state => state.globalConfig.iconBorderRadius,
  searchEngineList: state => state.searchEngineList,
  websiteList: state => state.websiteList,
  hotPlatformList: state => state.hotPlatformList
}
