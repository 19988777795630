export const UPDATE_GLOBAL_CONFIG = 'update_global_config' // 更新全局配置
export const RESET_GLOBAL_SETTING = 'reset_global_setting' // 重置全局设置
export const UPDATE_SEARCH_ENGINE_LIST = 'update_search_engine_list' // 更新搜索引擎列表
export const UPDATE_THEME = 'update_theme' // 更新主题
export const UPDATE_THEME_SCHEME = 'update_theme_scheme' // 更改预设主题
export const UPDATE_WEBSITE_LIST = 'update_website_list' // 更新网站列表
export const UPDATE_WEBSITE_CATEGORY = 'update_website_category' // 更新网站分类
export const UPDATE_WEBSITE_SITE_LIST = 'update_website_site_list' // 更新网站分类中的网站列表
export const UPDATE_WEBSITE_SITE = 'update_website_site' // 更新网站分类中的网站
export const ADD_WEBSITE_CATEGORY = 'add_website_category' // 添加网站分类
export const ADD_WEBSITE_SITE = 'add_website_site' // 添加网站分类中的网站
export const UPDATE_HOT_PLATFORM = 'update_hot_platform' // 更新订阅热榜列表
