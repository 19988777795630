import { getStore } from '@/utils/storage'
import { httpsFullReg, httpsReg } from '@/utils/regexps'
import {
  customSearchEngine,
  globalConfig as rawGlobalConfig,
  searchEngineList as rawSearchEngineList
} from '@/config'

let globalConfig = getStore({ key: 'globalConfig' })
if (!(Object.prototype.toString.call(globalConfig) === '[object Object]')) {
  globalConfig = {
    // 搜索设置
    searchSettings: {}
  }
}
const cusSearchEngine = getStore({ key: 'cusSearchEngine' })
const searchEngineList = [...rawSearchEngineList]
if (Object.prototype.toString.call(cusSearchEngine) === '[object Object]') {
  const searchUrl = cusSearchEngine.searchUrl
  if (httpsFullReg.test(searchUrl) && cusSearchEngine.key === 'custom') {
    customSearchEngine.url = searchUrl.match(httpsReg)[0]
    customSearchEngine.searchUrlPrefix = searchUrl
    searchEngineList.push(customSearchEngine)
  }
}
// 如果需要对搜索引擎列表排序或自定义顺序，单独再做一个排序方式，这里不在storage中保存整个搜索引擎列表，以防随意篡改。
const sortList = getStore({ key: 'engSortList' })
if (Array.isArray(sortList)) {
  for (let i = 0; i < sortList.length; i++) {
    const sortStr = sortList[i]
    const findIndex = searchEngineList.findIndex(item => item.key === sortStr)
    if (findIndex !== -1) {
      const element = searchEngineList[findIndex]
      searchEngineList.splice(findIndex, 1)
      searchEngineList.splice(i, 0, element)
    }
  }
}
let defaultEngine = globalConfig.searchSettings.defaultEngine
// 如果是列表第一项，不用再排序，若是被篡改信息，设置配置中还是自定义custom，而引擎列表内没有自定义搜索引擎，也会进入条件
if (defaultEngine) {
  if (defaultEngine !== searchEngineList[0].key) {
    const findIndex = searchEngineList.findIndex(item => item.key === defaultEngine)
    if (findIndex !== -1) {
      const element = searchEngineList[findIndex]
      searchEngineList.splice(findIndex, 1)
      searchEngineList.unshift(element)
    } else {
      // 前述条件符合之后进入这里说明被篡改信息，没有自定义引擎信息，而设置还是自定义custom，这里将引擎信息重置
      defaultEngine = searchEngineList[0].key
    }
  }
} else {
  // 这里是防止手动删除了存储里面defaultEngine值，而排序信息却又手动更改顺序导致实际默认引擎和存储顺序不一致的问题（也可以再重新排序恢复默认顺序）
  if (searchEngineList[0].key !== rawGlobalConfig.searchSettings.defaultEngine) {
    defaultEngine = searchEngineList[0].key
  }
}

let websiteList = getStore({ key: 'websiteList' })
if (!Array.isArray(websiteList)) {
  websiteList = []
}

let hotPlatformList = getStore({ key: 'hotPlatformList' })
if (!Array.isArray(hotPlatformList)) {
  hotPlatformList = []
}

export default {
  globalConfig: {
    iconBorderRadius: globalConfig.iconBorderRadius || rawGlobalConfig.iconBorderRadius,
    // 外部链接跳转方式，默认当前页
    externalJumpMethod: globalConfig.externalJumpMethod || rawGlobalConfig.externalJumpMethod,
    // 搜索设置
    searchSettings: {
      // 默认是搜索引擎列表第一个
      defaultEngine: defaultEngine || rawGlobalConfig.searchSettings.defaultEngine,
      // 跳转方式，默认当前页
      jumpMethod: globalConfig.searchSettings.jumpMethod || rawGlobalConfig.searchSettings.jumpMethod,
      // 搜索建议，默认不开启
      searchSuggestion: globalConfig.searchSettings.searchSuggestion || rawGlobalConfig.searchSettings.searchSuggestion,
      // 补全
      searchCompletion: globalConfig.searchSettings.searchCompletion || rawGlobalConfig.searchSettings.searchCompletion
    },
    hotPlatformSettings: {
      open: globalConfig.hotPlatformSettings ? globalConfig.hotPlatformSettings.open === true : true
    }
  },
  searchEngineList,
  theme: getStore({ key: 'theme' }) || (window.matchMedia('(prefers-color-scheme: dark)').matches && 'dark') || 'light',
  themeScheme: getStore({ key: 'themeScheme' }) || 'default',
  websiteList,
  hotPlatformList
}
