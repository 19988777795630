<template>
  <div id="fly-feather" class="fly-theme-transition">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: mapGetters(['theme']),
  created() {
    document.documentElement.setAttribute('theme', this.theme)
    const darkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    setTimeout(console.log.bind(console, `
%c欢迎使用飞羽起始页！
 _________      __        __         __
|   ______|    |  |      |  |       |  |
|  |           |  |       |  |     |  |
|  |______     |  |        |  |__|  |
|   ______|    |  |          |    |
|  |           |  |           |  |
|  |           |  |_______    |  |
|__|           |__________|   |__|

%c©2024 fly-feather All right reserved.`,
    'color: #41C4D6;',
    'color: ' + darkTheme ? '#fff;' : '#000;'))
  }
}
</script>

<style lang="scss">
#fly-feather {
  height: 100%;
  width: 100%;
  overflow: hidden auto;
  min-width: 1024px;
  background-color: var(--theme-bg);
  color: var(--theme-color);
  @media screen and (max-width: 480px) {
    min-width: auto;
  }
}
</style>
