<template>
  <button
    class="fly-button"
    :disabled="disabled || loading"
    :type="nativeType"
    :class="[
      buttonType,
      buttonSize,
      {
        'is-disabled': disabled,
        'is-loading': loading,
      }
    ]"
    @click="handleClick"
  >
    <div v-if="loading" class="icon-loading" />
    <svg-icon v-if="icon && !loading" :icon-class="icon" />
    <span v-if="$slots.default"><slot /></span>
  </button>
</template>
<script>
export default {
  name: 'FlyButton',

  props: {
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    loading: Boolean,
    disabled: Boolean
  },

  computed: {
    buttonType() {
      return this.type ? 'fly-button--' + this.type : ''
    },
    buttonSize() {
      return this.size ? 'fly-button--' + this.size : ''
    }
  },

  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    }
  }
}
</script>
<style scoped>
.icon-loading {
  display: inline-block;
  vertical-align: -0.15em;
  margin-right: 5px;
  width: 1em;
  height: 1em;
  overflow: hidden;
  background-image: url(~@/assets/imgs/loading.png);
  background-size: 100% 100%;
  animation: ratate 1s infinite linear;
}
@keyframes ratate {
  to {
    transform: rotate(360deg);
  }
}
</style>
